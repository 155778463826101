<div class="container my-2 footer-links">
  <h2>Datenschutz</h2>
  <ng-container *ngIf="footer$ | select: 'settings' | async as footer">
    <div [innerHTML]="settingByKey(footer, 'datenschutz').value"></div>

  </ng-container>
</div>



