import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './features/dashboard/components/dashboard/dashboard.component';
import {ContentComponent} from './features/content/components/content/content.component';
import {ToolsComponent} from './features/tools/components/tools/tools.component';
import {CourseDetailComponent} from './features/content/components/course-detail/course-detail.component';
import {LoginComponent} from './features/auth/components/login/login.component';
import {CourseDetailVideoComponent} from './features/content/components/course-detail-video/course-detail-video.component';
import {PasswordAssistanceComponent} from './features/auth/components/password-assistance/password-assistance.component';
import {PasswordResetComponent} from './features/auth/components/password-reset/password-reset.component';
import {DatenschutzComponent} from './shared/components/datenschutz/datenschutz.component';
import {ImpressumComponent} from './shared/components/impressum/impressum.component';
import {AgbComponent} from './shared/components/agb/agb.component';
import {AuthGuard} from './core/guards/auth.guard';
import {GuestGuard} from './core/guards/guest.guard';
import {ToolDetailComponent} from "./features/tools/components/tool-detail/tool-detail.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inhalte',
    component: ContentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tools',
    component: ToolsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tools/:id',
    component: ToolDetailComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'inhalte/:id',
    component: CourseDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inhalte/:courseId/lektionen/:lectionId',
    component: CourseDetailVideoComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'password-assistance',
    component: PasswordAssistanceComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'password-assistance/:token',
    component: PasswordResetComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'agb',
    component: AgbComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
