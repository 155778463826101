import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[confirm]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ConfirmDirective, multi: true }
  ]
})
export class ConfirmDirective implements Validator{

  @Input() confirm: string[] = [];

  constructor() { }


  validate(formGroup: FormGroup): ValidationErrors | null {
    return this.equals(this.confirm[0], this.confirm[1])(formGroup);
  }

  equals(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      // return null if controls haven't initialised yet
      if (!control || !matchingControl) {
        return null;
      }

      // return null if another validator has already found an error on the matchingControl
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return null;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

}
