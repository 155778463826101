import { Component, OnInit, ViewChild } from '@angular/core';
import {ProductGQL} from "../../../../services/graphql.service";
import {ActivatedRoute, Params} from "@angular/router";
import {LoadingService} from "../../../../core/services/loading.service";
import { AuthService } from 'src/app/core/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tool-detail',
  templateUrl: './tool-detail.component.html',
  styleUrls: ['./tool-detail.component.scss']
})
export class ToolDetailComponent implements OnInit {

  showLoader = false;
  productId: any;
  products$: any;
  constructor(public productGQL: ProductGQL,
              public activatedRoute: ActivatedRoute,
              public loadingService: LoadingService,
              public authService: AuthService,
              public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.productId = params.id;
        this.products$ = this.productGQL.watch({slug: this.productId});
        this.loadingService.setLoading();
      }
    );
  }
  settingByKey(all, key) {
    return all.filter(setting => setting.key === key)[0];
  }
  
}
