import {Component, Input, OnInit} from '@angular/core';
import {CourseGQL} from "../../../../services/graphql.service";
import {ActivatedRoute, Params} from "@angular/router";
import {DoneService} from '../../../../core/services/done.service';
import _ from 'lodash';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit {
  isOpen = true;
  courseId: any;
  course$: any;
  constructor(public courseGQL: CourseGQL,
              public activatedRoute: ActivatedRoute,
              public doneService: DoneService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.courseId = params.id;
        this.course$ = this.courseGQL.watch({id: this.courseId});
      }
    );

  }

  getLectionLength(modules): number {
    return _.sumBy(modules, (m) => m.lections.length);
  }

}
