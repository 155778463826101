import { Injectable } from '@angular/core';
import {User} from '../../services/graphql.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public LOCAL_STORAGE_PATH = 'big_rate_club_jwt_token';
  private user: User;

  constructor() {
  }

  public setToken(token): void {
    localStorage.setItem(this.LOCAL_STORAGE_PATH, token);
  }

  public getToken(): string {
    return localStorage.getItem(this.LOCAL_STORAGE_PATH);
  }

  public removeToken(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_PATH);
  }

  public logout(): void {
    this.removeToken();
    window.location.href = '/';
  }

  public hasToken(): boolean {
    return !!this.getToken();
  }

  public setUser(user: User): void {
    this.user = user;
  }

  public getUser(): User {
    return this.user;
  }

  check(): boolean {
    return !!this.getUser();
  }
}
