<ng-container *ngIf="course$ | select: 'courseById'  | async as course">
  <div class="hero">
    <div class="text">
      {{ course.name}}
      <div class="sub d-flex justify-content-center align-items-center">
        <div class="row mt-3">
          <div class="module mr-4">
            <div class="icon">
              <i class="las la-th-list"></i>
            </div>
            <div class="content">
              <span *ngIf="course.modules.length === 1">
                1 Modul
              </span>
              <span *ngIf="course.modules.length !== 1">
                {{ course.modules.length }} Module
              </span>
            </div>
          </div>
          <div class="lesson">
            <div class="icon">
              <i class="las la-list"></i>
            </div>
            <div class="content">
              <span *ngIf="getLectionLength(course.modules) === 1">
                1 Lektion
              </span>
              <span *ngIf="getLectionLength(course.modules) !== 1">
                {{getLectionLength(course.modules)}} Lektionen
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="padding-wrapper">
    <div class="site-heading">
      Kursprogramm
    </div>
    <div class="my-4" *ngFor="let moduleData of course.modules; let i= index">
        <app-course-step [index]="i" [title]="moduleData.name">
          <app-course-step-item *ngFor="let lection of moduleData.lections" [isDone]="doneService.isDone(lection.id)" routerLink="/inhalte/{{courseId}}/lektionen/{{lection.id}}">
            {{ lection.name }}
          </app-course-step-item>
        </app-course-step>
    </div>
  </div>
</ng-container>

