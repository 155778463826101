import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldAddToken(request) && this.hasToken(request)) {
      request = this.addToken(request);
    }
    return next.handle(request);
  }

  shouldAddToken(request): boolean {
    return true;
  }

  hasToken(request): boolean {
    return this.authService.hasToken();
  }

  getToken(request): string {
    return this.authService.getToken();
  }

  addToken(request): HttpRequest<unknown> {
    return request.clone({headers: request.headers.set('Authorization', 'Bearer ' + this.getToken(request))});
  }

}
