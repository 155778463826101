import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from "../../../../core/services/layout.service";
import {ActivatedRoute, Params} from "@angular/router";
import {ResetPasswordGQL} from "../../../../services/graphql.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  token;
  email;
  step = 1;
  password: any;
  passwordConfirm: any;

  constructor(public layoutService: LayoutService,
              public activatedRoute: ActivatedRoute,
              public resetPasswordGQL: ResetPasswordGQL) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.layoutService.showLayout = false;
    });
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.token = params.token;
      }
    );
  }

  ngOnDestroy(): void {
    this.layoutService.showLayout = true;
  }

  resetPassword(): void {
    this.resetPasswordGQL.mutate({password: this.password, token: this.token, email: this.email}).subscribe(() => {
      this.step = 2;
    });
  }
}
