import {AfterContentInit, Component, ContentChildren, Input, OnInit} from '@angular/core';
import {CourseStepItemComponent} from "../course-step-item/course-step-item.component";
import {CourseGQL} from '../../../services/graphql.service';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-course-step',
  templateUrl: './course-step.component.html',
  styleUrls: ['./course-step.component.scss']
})
export class CourseStepComponent implements OnInit {
  @Input() title: any;
  @Input() index: number;
 @ContentChildren(CourseStepItemComponent) public items;
  isOpen = false;
  course;
  courseId;
  constructor() { }

  ngOnInit(): void {
    if (this.index === 0){
      this.isOpen = true;
    }

  }

  doneItemCount() {
    return this.items.filter((e) => e.isDone).length;
  }



}
