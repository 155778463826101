import { Injectable } from '@angular/core';
import {MeGQL, UpdateDoneStateGQL} from '../../services/graphql.service';

@Injectable({
  providedIn: 'root'
})
export class DoneService {

  constructor(public updateDoneState: UpdateDoneStateGQL,
              public meGQL: MeGQL) {
    this.meGQL.watch().valueChanges.subscribe((res) => {
      this.dones = [...res.data.me.doneLectionIds] || [];
    });
  }

  public dones = [];

  markAsDone(id): void {
    this.updateDoneState.mutate({lectionId: id, read: true}).subscribe();
    this.dones.push(id);
  }

  markAsUnDone(id): void {
    this.updateDoneState.mutate({lectionId: id, read: false}).subscribe();
    this.dones = this.dones.filter(i => i !== id);
  }

  isDone(id): boolean {
    return this.dones.includes(id);
  }

}
