<div class="row m-0 height-full width-full">
  <div class="p-0 col-lg-6 d-none d-lg-block width-full">
    <div class="background"></div>
  </div>
  <div class="p-0 col-12 col-lg-6 d-flex align-items-center width-full">
    <div class="container d-flex justify-content-center">
      <div class="wrapper">
        <div class="logo d-flex justify-content-center align-items-center mb-5">
          <img src="assets/logo.png" alt="">
        </div>
        <div *ngIf="message">
          <div class="alert alert-success">
            Wir haben an die E-Mail-Adresse <b>{{ email }}</b> eine E-Mail geschickt.
          </div>
        </div>
        <form #emailForm='ngForm' (ngSubmit)="sendMail()">
          <div class="info my-2">
            Gib die E-Mail zu deinem Account ein und wir senden dir einen Link zum zurücksetzen.
          </div>
          <input placeholder="E-Mail" type="email" [(ngModel)]="email" name="email" #emailForm="ngModel" required>
          <div class="label" *ngIf="!(emailForm.invalid && emailForm.touched)">
            E-Mail
          </div>
          <span class="form-error" *ngIf="emailForm.invalid && emailForm.touched">
          E-Mail darf nicht leer sein.
        </span>
          <button class="mt-5" [disabled]="emailForm.invalid" type="submit">E-Mail senden</button>
        </form>

        <div class="d-flex justify-content-between">
          <div class="text-center mt-3">
            <a routerLink="/login">Zurück zum Login</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer d-flex align-items-center justify-content-center">
      <div class="row links">
        <a routerLink="/impressum">Impressum</a>
        <div class="divider">
          |
        </div>
        <a routerLink="/datenschutz">Datenschutz</a>
      </div>
    </div>
  </div>

</div>


