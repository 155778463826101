
<div class="bar d-flex align-items-center justify-content-between" (click)="isOpen = !isOpen">
  <div class="left d-flex align-items-center">
    <i class="las la-th-list"></i>
    {{ title }}
  </div>
  <div class="right d-flex align-items-center">
    <div class="step-progress">
      {{ doneItemCount() }} / {{ items.length }}
    </div>
    <div class="icon-open d-flex align-items-center" *ngIf="isOpen">
      <i class="las la-angle-up"></i>
    </div>
    <div class="icon-close d-flex align-items-center" *ngIf="!isOpen">
      <i class="las la-angle-down"></i>
    </div>
  </div>
</div>
<ng-content *ngIf="isOpen"></ng-content>

