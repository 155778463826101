<div class="hero">
  <div class="text">
    Kursübersicht
  </div>
</div>
<div class="container">
  <div class="wrapper">
    <div class="heading">Kurse</div>
    <ng-container *ngIf="content">
      <div class="row">
        <div class="course" *ngFor="let course of content">
          <div class="course-card" routerLink="/inhalte/{{course.id}}">
            <div class="click-course">
              <div class="pic">
                <img [src]="course.image_path | imagePath" alt="">
              </div>
              <div class="content">
                <div class="heading">
                  {{course.name}}
                </div>
                <div class="text" [innerHTML]="course.description">
                </div>
                <div class="start-now">
                  jetzt starten
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  </div>

