import { Component, OnInit } from '@angular/core';
import {ContentGQL} from "../../../../services/graphql.service";
import _ from "lodash";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  course: any;
  content: any;

  constructor(public contentGQL: ContentGQL) {

  }

  ngOnInit(): void {
    this.contentGQL.fetch().subscribe( res => {
      this.content = _.orderBy(res.data.courses, ['sort_id'], ['asc']);
    })

  }



}
