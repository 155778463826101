<ng-container *ngIf="products$ | select:'productBySlug' | withLoading | async as product">
  <div class="hero">
    <div class="text">
      {{ product.name}}
    </div>
  </div>
  <div class="wrapper d-flex justify-content-center align-items-center">
    <iframe (click)="console.log('click')" [src]="sanitizer.bypassSecurityTrustResourceUrl(product.url + '?token=' + authService.getToken())"></iframe>
    <!--<div class="brc-card">
      <div class="row">
        <div class="col-4 p-0">
          <div class="image">
            <img src="{{product.image_path | imagePath}}" alt="{{ product.name }}">
          </div>
        </div>
        <div class="col-8">
          <div class="brc-card-content">
            <div class="brc-card-title">{{ product.name }}</div>
            <div class="description" [innerHTML]="product.description | safe: 'html'"></div>
            <div class="target d-flex justify-content-center align-items-center">
              <a class="btn mt-3 mt-2" href="{{product.url}}" target="_blank">
                Zum Inhalt
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</ng-container>

