<ng-container *ngIf="contentDetail$ | select: '' | async as contentDetail">

  <div class="hero">
    <div class="text">
      <div class="top-sub d-flex justify-content-center align-items-center">
        <div class="row mt-3 d-flex justify-content-center">
          <div class="course" *ngIf="contentDetail.data.courseById as course">
            {{course.name}}
          </div>
          <div class="module" *ngIf="contentDetail.data.lectionById.module as module">
            {{module.name}}
          </div>
        </div>
      </div>
      {{ contentDetail.data.lectionById.name }}
    </div>
  </div>

  <div class="wrapper">
    <div class="site-heading">
      {{ contentDetail.data.lectionById.name }}
    </div>

    <div class="row">
      <div class="col-xl-7 d-flex justify-content-center justify-content-xl-start">
        <div class="video-wrapper">
          <div class="video" [innerHTML]="contentDetail.data.lectionById.content | safe: 'html'">

          </div>
          <div class="next d-flex justify-content-end align-items-center float-right" (click)="next(contentDetail.data.courseById, contentDetail.data.lectionById.id)">
            Mit der nächsten Lektion fortfahren
            <i class="las la-angle-right"></i>
          </div>
        </div>

      </div>
      <div class="col-xl-5">
        <ng-container *ngIf="course$ | select: 'courseById' | async as course">
          <div class="steps-card">
            <div class="step" *ngFor="let moduleContent of course.modules; let i = index">
              <div class="step-heading" (click)="showHeading[i] = !showHeading[i]">
                <i class="las la-th-list"></i>
                {{ moduleContent.name }}
                <div class="showHeading-icon">
                  <i class="las la-angle-up" *ngIf="!showHeading[i]"></i>
                  <i class="las la-angle-down" *ngIf="showHeading[i]"></i>
                </div>
              </div>
              <div class="step-content" *ngIf="showHeading[i]">
                <div class="step-item" *ngFor="let lectionContent of moduleContent.lections" >
<!--                  {{lectionContent | json}}-->
                  <i class="las la-check-circle" *ngIf="doneService.isDone(lectionContent.id)" (click)="doneService.markAsUnDone(lectionContent.id)"></i>
                  <i class="las la-circle" *ngIf="!doneService.isDone(lectionContent.id)" (click)="doneService.markAsDone(lectionContent.id)"></i>
                  <span routerLink="/inhalte/{{courseId}}/lektionen/{{lectionContent.id}}">
                    {{ lectionContent.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
      </div>
    </div>
  </div>
</ng-container>


