import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
};



export type Course = {
  __typename?: 'Course';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
  description: Scalars['String'];
  sort_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  modules: Array<Maybe<Module>>;
};



export type Lection = {
  __typename?: 'Lection';
  id: Scalars['ID'];
  name: Scalars['String'];
  module?: Maybe<Module>;
  content: Scalars['String'];
  is_done?: Maybe<Scalars['Boolean']>;
};

export type Module = {
  __typename?: 'Module';
  id: Scalars['ID'];
  name: Scalars['String'];
  course?: Maybe<Course>;
  lections: Array<Maybe<Lection>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login?: Maybe<Scalars['String']>;
  forgetPassword?: Maybe<Scalars['String']>;
  resetPassword?: Maybe<Scalars['String']>;
  updateDoneState: Array<Maybe<Scalars['ID']>>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateDoneStateArgs = {
  lectionId: Scalars['ID'];
  read?: Maybe<Scalars['Boolean']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  image_path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  settings?: Maybe<Array<Maybe<Setting>>>;
  settingByKey?: Maybe<Setting>;
  courses: Array<Maybe<Course>>;
  courseById?: Maybe<Course>;
  moduleById?: Maybe<Module>;
  lectionById?: Maybe<Lection>;
  productById?: Maybe<Product>;
  productBySlug?: Maybe<Product>;
  products?: Maybe<Array<Maybe<Product>>>;
};


export type QuerySettingByKeyArgs = {
  key: Scalars['String'];
};


export type QueryCourseByIdArgs = {
  id: Scalars['ID'];
};


export type QueryModuleByIdArgs = {
  id: Scalars['ID'];
};


export type QueryLectionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryProductByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProductBySlugArgs = {
  slug: Scalars['String'];
}

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  order_date: Scalars['DateTime'];
  order_id: Scalars['String'];
  state: Scalars['String'];
  type: Scalars['String'];
  email: Scalars['String'];
  email_verified_at?: Maybe<Scalars['DateTime']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  doneLectionIds: Array<Maybe<Scalars['ID']>>;
};

export type ContentQueryVariables = Exact<{ [key: string]: never; }>;


export type ContentQuery = (
  { __typename?: 'Query' }
  & { courses: Array<Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'id' | 'name' | 'description' | 'image_path' | 'sort_id'>
  )>> }
);

export type ToolsQueryVariables = Exact<{ [key: string]: never; }>;


export type ToolsQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'description' | 'image_path' | 'url'>
  )>>> }
);

export type CourseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CourseQuery = (
  { __typename?: 'Query' }
  & { courseById?: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'id' | 'name' | 'description'>
    & { modules: Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'id' | 'name'>
      & { lections: Array<Maybe<(
        { __typename?: 'Lection' }
        & Pick<Lection, 'id' | 'name' | 'content' | 'is_done'>
      )>> }
    )>> }
  )> }
);

export type ProductQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProductQuery = (
  { __typename?: 'Query' }
  & { productById?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'description' | 'image_path' | 'category' | 'url'>
  )> }
);

export type ContentDetailQueryVariables = Exact<{
  courseId: Scalars['ID'];
  lectionId: Scalars['ID'];
}>;


export type ContentDetailQuery = (
  { __typename?: 'Query' }
  & { lectionById?: Maybe<(
    { __typename?: 'Lection' }
    & Pick<Lection, 'id' | 'name' | 'content' | 'is_done'>
    & { module?: Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'name'>
    )> }
  )>, courseById?: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'id' | 'name' | 'description'>
    & { modules: Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'id' | 'name'>
      & { lections: Array<Maybe<(
        { __typename?: 'Lection' }
        & Pick<Lection, 'id' | 'name' | 'content' | 'is_done'>
      )>> }
    )>> }
  )> }
);

export type FooterQueryVariables = Exact<{ [key: string]: never; }>;


export type FooterQuery = (
  { __typename?: 'Query' }
  & { settings?: Maybe<Array<Maybe<(
    { __typename?: 'Setting' }
    & Pick<Setting, 'key' | 'value'>
  )>>> }
);

export type UpdateDoneStateMutationVariables = Exact<{
  lectionId: Scalars['ID'];
  read: Scalars['Boolean'];
}>;


export type UpdateDoneStateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDoneState'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'doneLectionIds'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type ForgetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgetPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export const ContentDocument = gql`
    query content {
  courses {
    id
    name
    description
    image_path
    sort_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentGQL extends Apollo.Query<ContentQuery, ContentQueryVariables> {
    document = ContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ToolsDocument = gql`
    query tools {
  products {
    id
    name
    description
    image_path
    url
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ToolsGQL extends Apollo.Query<ToolsQuery, ToolsQueryVariables> {
    document = ToolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CourseDocument = gql`
    query course($id: ID!) {
  courseById(id: $id) {
    id
    name
    description
    modules {
      id
      name
      lections {
        id
        name
        content
        is_done
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CourseGQL extends Apollo.Query<CourseQuery, CourseQueryVariables> {
    document = CourseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductDocument = gql`
    query product($slug: String!) {
  productBySlug(slug: $slug) {
    id
    name
    description
    image_path
    category
    url
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductGQL extends Apollo.Query<ProductQuery, ProductQueryVariables> {
    document = ProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContentDetailDocument = gql`
    query contentDetail($courseId: ID!, $lectionId: ID!) {
  lectionById(id: $lectionId) {
    id
    name
    content
    is_done
    module {
      name
    }
  }
  courseById(id: $courseId) {
    id
    name
    description
    modules {
      id
      name
      lections {
        id
        name
        content
        is_done
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentDetailGQL extends Apollo.Query<ContentDetailQuery, ContentDetailQueryVariables> {
    document = ContentDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FooterDocument = gql`
    query footer {
  settings {
    key
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FooterGQL extends Apollo.Query<FooterQuery, FooterQueryVariables> {
    document = FooterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDoneStateDocument = gql`
    mutation updateDoneState($lectionId: ID!, $read: Boolean!) {
  updateDoneState(lectionId: $lectionId, read: $read)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDoneStateGQL extends Apollo.Mutation<UpdateDoneStateMutation, UpdateDoneStateMutationVariables> {
    document = UpdateDoneStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query me {
  me {
    id
    first_name
    last_name
    email
    doneLectionIds
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgetPasswordDocument = gql`
    mutation forgetPassword($email: String!) {
  forgetPassword(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgetPasswordGQL extends Apollo.Mutation<ForgetPasswordMutation, ForgetPasswordMutationVariables> {
    document = ForgetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $password: String!, $token: String!) {
  resetPassword(email: $email, password: $password, token: $token)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }