import { Component, OnInit } from '@angular/core';
import {ContentGQL, ToolsGQL} from '../../../../services/graphql.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {
  tool: any;

  constructor(public tools$: ToolsGQL) { }

  ngOnInit(): void {
  }

}
