import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './components/content/content.component';
import {CourseDetailComponent} from "./components/course-detail/course-detail.component";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared/shared.module";
import {CourseDetailVideoComponent} from "./components/course-detail-video/course-detail-video.component";



@NgModule({
  declarations: [
    ContentComponent,
    CourseDetailComponent,
    CourseDetailVideoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ]
})
export class ContentModule { }
