<div class="navbar">
  <div class="wrapper d-flex align-items-center justify-content-between">
    <div class="logo d-flex align-items-center">
      <img src="assets/logo.png" alt="">
    </div>
    <div class="nav" [class.open]="isOpen">
      <div class="nav-item" routerLink="/dashboard" routerLinkActive="active">
        <a>Dashboard</a>
      </div>
      <div class="nav-item" routerLink="/inhalte" routerLinkActive="active">
        <a>Inhalte</a>
      </div>
      <div class="nav-item" routerLink="/tools" routerLinkActive="active">
        <a>Tools</a>
      </div>
      <div class="nav-item" routerLinkActive="active">
        <a href="https://www.facebook.com/groups/642935136398857" target="_blank">Community</a>
      </div>
      <div class="nav-item" routerLinkActive="active">
        <a href="https://support.bigrate.de/" target="_blank">Support</a>
      </div>
    </div>
    <div class="profile" >
      <button (click)="authService.logout()">Logout</button>
    </div>
    <div class="menu">
      <i class="las la-bars" (click)="isOpen = !isOpen"></i>
    </div>
  </div>
</div>

