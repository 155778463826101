import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {ContentModule} from "./content/content.module";
import { LoginComponent } from './auth/components/login/login.component';
import {RouterModule} from "@angular/router";
import { PasswordAssistanceComponent } from './auth/components/password-assistance/password-assistance.component';
import { PasswordResetComponent } from './auth/components/password-reset/password-reset.component';
import {FormsModule} from "@angular/forms";
import {ToolsModule} from "./tools/tools.module";



@NgModule({
  declarations: [

  LoginComponent,

  PasswordAssistanceComponent,

  PasswordResetComponent],
  imports: [
    DashboardModule,
    ContentModule,
    ToolsModule,
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule
  ]
})
export class FeaturesModule { }
