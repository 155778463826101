import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {
  ContentDetailGQL,
  CourseGQL,
  MutationUpdateDoneStateArgs,
  UpdateDoneStateGQL
} from "../../../../services/graphql.service";
import {DoneService} from "../../../../core/services/done.service";

@Component({
  selector: 'app-course-detail-video',
  templateUrl: './course-detail-video.component.html',
  styleUrls: ['./course-detail-video.component.scss']
})
export class CourseDetailVideoComponent implements OnInit {
  contentDetail$;
  lectionId;
  courseId;
  course$;
  index = 0;
  showHeading: boolean[] = [];


  constructor(public activatedRoute: ActivatedRoute,
              public contentDetailGQL: ContentDetailGQL,
              public courseGQL: CourseGQL,
              public updateDoneState: UpdateDoneStateGQL,
              public doneService: DoneService,
              public router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.lectionId = params.lectionId;
        this.courseId = params.courseId;
        this.contentDetail$ = this.contentDetailGQL.watch({courseId: this.courseId, lectionId: this.lectionId});
        this.course$ = this.courseGQL.watch({id: this.courseId});
      }
    );
  }

  getNextLection(course, id) {
    const lections = course.modules.map(m => m.lections).flat();
    const index = lections.findIndex(l => l.id === id);
    return lections[index + 1];
  }

  next(course, id) {
    const lection = this.getNextLection(course, id);
    if(lection) {
      this.doneService.markAsDone(id);
      this.router.navigateByUrl('/inhalte/' + this.courseId + '/lektionen/' + lection.id );
    }
  }
}
