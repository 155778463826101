<div class="wrapper" *ngIf="footer$ | select: 'settings' | async as footer">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-3">
          <div class="logo d-flex justify-content-center align-items-center">
            <img src="assets/logo.png" alt="">
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="social-media d-flex justify-content-center align-items-center">
              <div class="icon">
                <a href="{{ settingByKey(footer, 'facebook').value }}" target="_blank"><i class="lab la-facebook"></i></a>
              </div>
              <div class="icon">
                <a href="{{ settingByKey(footer, 'instagram').value }}" target="_blank"><i class="lab la-instagram"></i></a>
              </div>
              <div class="icon">
                <a href="{{ settingByKey(footer, 'youtube').value }}" target="_blank"><i class="lab la-youtube"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 p-1">
          <div class="row">
            <div class="col-md-4 mb-5 mb-md-0 md-0">
              <div class="footer-heading">
                Seitenübersicht
              </div>
              <ul class="cursor-pointer">
                <li routerLink="/dashboard">Dashboard</li>
                <li routerLink="/inhalte">Inhalte</li>
                <li routerLink="/tools">Tools</li>
                <li><a href="https://www.facebook.com/groups/642935136398857" target="_blank">Community</a></li>
                <li><a href="https://support.bigrate.de/" target="_blank">Support</a></li>
              </ul>
            </div>
            <div class="col-md-4 mb-5 mb-md-0">
              <div class="footer-heading">
                Rechtliches
              </div>
              <ul class="cursor-pointer">
                <li routerLink="/impressum">Impressum</li>
                <li routerLink="/datenschutz">Datenschutz</li>
                <li routerLink="/agb">AGB</li>
              </ul>

            </div>
            <div class="col-md-4 mb-5 mb-md-0">
              <div class="footer-heading">
                Kontakt
              </div>
              <ul>
                <li  [innerHTML]="settingByKey(footer, 'address').value"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
