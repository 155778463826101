import { Component, OnInit } from '@angular/core';
import {FooterGQL, MeGQL} from '../../../../services/graphql.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  isOpen = true;

  constructor(public me$: MeGQL,
              public footer$: FooterGQL) { }

  ngOnInit(): void {
  }

  settingByKey(all, key) {
    return all.filter(setting => setting.key === key)[0];
  }
}
