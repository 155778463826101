import { Component, OnInit } from '@angular/core';
import {FooterGQL} from "../../../services/graphql.service";

@Component({
  selector: 'app-help-cta',
  templateUrl: './help-cta.component.html',
  styleUrls: ['./help-cta.component.scss']
})
export class HelpCtaComponent implements OnInit {
  link = ' ';
  isOpen = true;
  constructor(public footer$: FooterGQL) { }

  ngOnInit(): void {
  }
  settingByKey(all, key) {
    return all.filter(setting => setting.key === key)[0];
  }
}
