import {Component, OnInit} from '@angular/core';
import {LayoutService} from './core/services/layout.service';
import {MeGQL} from './services/graphql.service';
import {AuthService} from './core/services/auth.service';
import {LoadingService} from "./core/services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'big-rate-club-angular';
  loaded = false;

  constructor(public layoutService: LayoutService,
              public me$: MeGQL,
              public loadingService: LoadingService,
              public authService: AuthService) {}

  ngOnInit(): void {
    this.me$.fetch().subscribe((res: any) => {
      this.authService.setUser(res.data.me);
      this.loaded = true;
    });
  }


}
