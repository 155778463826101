import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagePath'
})
export class ImagePathPipe implements PipeTransform {
  serverUrl = 'https://admin.bigrate-club.de/storage/';
  transform(value: unknown, ...args: unknown[]): unknown {
    return this.serverUrl.concat(<string>value);
  }

}
