<div class="row m-0 height-full width-full">
  <div class="p-0 col-lg-6 d-none d-lg-block width-full">
    <div class="background"></div>
  </div>
  <div class="p-0 col-12 col-lg-6 d-flex align-items-center width-full">
    <div class="container d-flex justify-content-center">
      <div class="wrapper" *ngIf="step === 1">
        <div class="logo d-flex justify-content-center align-items-center mb-5">
          <img src="assets/logo-01.png" alt="">
        </div>
        <form #resetForm='ngForm' (ngSubmit)="resetPassword()">
          <div class="info my-2">
            Vergib ein neues Passwort.
          </div>
          <input class="my-3" placeholder="E-Mail" type="email" [(ngModel)]="email" name="email" required>
          <input class="my-1" placeholder="Passwort" type="password" [(ngModel)]="password" name="email" required>
          <input class="my-1" placeholder="Passwort wiederholen" type="password" [(ngModel)]="passwordConfirm" name="email" required>
          <span class="form-error" *ngIf="resetForm.invalid && resetForm.touched">
          Passwort Feld darf nicht leer sein.
        </span>
          <button class="mt-5" [disabled]="resetForm.invalid && password === passwordConfirm">Passwort zurücksetzen</button>
        </form>

        <div class="d-flex justify-content-between">
          <div class="text-center mt-3">
            <a routerLink="/login">Zurück zum Login</a>
          </div>
        </div>
      </div>
      <div class="wrapper" *ngIf="step === 2">
        <div class="logo d-flex justify-content-center align-items-center mb-5">
          <img src="assets/logo-01.png" alt="">
        </div>
        <div class="icon d-flex justify-content-center align-items-center">
          <i class="las la-check-circle"></i>
        </div>
        <div class="done-text d-flex justify-content-center align-items-center">
          Dein Passwort wurde zurückgesetzt
        </div>
        <div class="d-flex justify-content-between">
          <div class="text-center mt-3">
            <a routerLink="/login">Zurück zum Login</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer d-flex align-items-center justify-content-center">
      <div class="row links">
        <a routerLink="/impressum">Impressum</a>
        <div class="divider">
          |
        </div>
        <a routerLink="/datenschutz">Datenschutz</a>
      </div>
    </div>
  </div>

</div>


