<ng-container *ngIf="isOpen">
  <div class="wrapper d-flex align-items-center justify-content-center">
    <div class="content">
      <div class="image">
        <img src="assets/1zu1.png" alt="">
      </div>
      <div class="text">
        Du benötigst Hilfe oder möchtest Deine Prozesse optimieren? <br>
        Im BigRate 1zu1 Coaching hast du Jonas ganz alleine für dich ☺
      </div>
      <div class="button"  *ngIf="footer$ | select: 'settings' | async as footer">
        <a href="{{ settingByKey(footer, 'jonas_button').value }}" target="_blank">
        <button>Jonas buchen!</button>
        </a>
      </div>

    </div>
  </div>
</ng-container>


<!--

<div class="button"  *ngIf="footer$ | select: 'settings' | async as footer">
  <a href="{{ settingByKey(footer, 'jonas_button').value }}">
    <button>Jonas buchen!</button>
  </a>

-->
