import { Pipe, PipeTransform } from '@angular/core';
import {Query, QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'select'
})
export class SelectPipe implements PipeTransform {

  transform(obj: any, name?: string): any {
    if (obj instanceof Query) {
      obj = obj.watch();
    }
    if (obj instanceof QueryRef) {
      return obj.valueChanges.pipe(map((data) =>
        (name && data && data) ? name.split('.').reduce((prev, curr) => {
          return prev ? prev[curr] : undefined;
        }, data.data) : data
      ));
    }

  }

}
