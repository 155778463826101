<div class="hero">
  <div class="text">
    Toolübersicht
  </div>
</div>
<ng-container *ngIf="tools$ | select:'products' | withLoading | async as tools">
  <div class="container">
    <div class="wrapper">
      <div class="heading">Tools</div>
      <div class="row">
        <div class="course" *ngFor="let tool of tools">
          <a routerLink="/tools/{{tool.slug}}">
            <div class="course-card">
              <div class="click-course">
                <div class="pic">
                  <img [src]="tool.image_path | imagePath" alt="">
                </div>
                <div class="content">
                  <div class="heading">
                    {{ tool.name }}
                  </div>
                  <div class="text" [innerHTML]="tool.description">
                    {{ tool | json }}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  </div>
                  <div class="start-now">
                    jetzt starten
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
