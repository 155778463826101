import { NgModule } from '@angular/core';
import {AsyncPipe, CommonModule} from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import {RouterModule} from "@angular/router";
import { FooterComponent } from './components/footer/footer.component';
import {HelpCtaComponent} from "./components/help-cta/help-cta.component";
import { CourseStepComponent } from './components/course-step/course-step.component';
import { CourseStepItemComponent } from './components/course-step-item/course-step-item.component';
import { SelectPipe } from './pipes/select.pipe';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { ImagePathPipe } from './pipes/image-path.pipe';
import { ConfirmDirective } from './directives/confirm.directive';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { DatenschutzComponent } from './components/datenschutz/datenschutz.component';
import { AgbComponent } from './components/agb/agb.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SafePipe } from './pipes/safe.pipe';



@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    HelpCtaComponent,
    CourseStepComponent,
    CourseStepItemComponent,
    SelectPipe,
    WithLoadingPipe,
    ImagePathPipe,
    ConfirmDirective,
    ImpressumComponent,
    DatenschutzComponent,
    AgbComponent,
    LoadingComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    HelpCtaComponent,
    CourseStepComponent,
    CourseStepItemComponent,
    SelectPipe,
    WithLoadingPipe,
    ImagePathPipe,
    LoadingComponent,
    SafePipe
  ]
})
export class SharedModule { }
