import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../../../../core/services/layout.service';
import {LoginGQL} from '../../../../services/graphql.service';
import {AuthService} from "../../../../core/services/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  public email;
  public password;
  public error = false;

  constructor(public layoutService: LayoutService,
              public loginGQL: LoginGQL,
              public authService: AuthService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.layoutService.showLayout = false;
    });
  }

  ngOnDestroy(): void {
    this.layoutService.showLayout = true;
  }

  login(): void {
    this.loginGQL.mutate({
      email: this.email,
      password: this.password
    }, {
      errorPolicy: 'all'
    }).subscribe((res) => {
      if (res.data.login) {
        this.authService.setToken(res.data.login);
        window.location.href = '/dashboard';
      } else {
        this.error = true;
      }
    });
  }
}
