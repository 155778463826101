import { NgModule } from '@angular/core';
import { ToolsComponent } from './components/tools/tools.component';
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared/shared.module";
import {CommonModule} from "@angular/common";
import {ToolDetailComponent} from "./components/tool-detail/tool-detail.component";



@NgModule({
  declarations: [
    ToolsComponent,
    ToolDetailComponent
  ],
    imports: [
        RouterModule,
        SharedModule,
      CommonModule
    ]
})
export class ToolsModule { }
