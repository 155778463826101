<div class="row m-0 height-full width-full">
  <div class="p-0 col-lg-6 d-none d-lg-block width-full">
    <div class="background"></div>
  </div>
  <div class="p-0 col-12 col-lg-6 d-flex align-items-center width-full">
    <div class="container d-flex justify-content-center">
      <div class="wrapper">
        <form (ngSubmit)="login()">
          <div class="logo d-flex justify-content-center align-items-center mb-5">
            <img src="assets/logo.png" alt="">
          </div>
          <div class="alert alert-danger" *ngIf="error">
            Die Zugangsdaten sind ungültig. Bitte probiere es erneut.
          </div>
          <input type="email" placeholder="mail@example.com" [(ngModel)]="email" name="email" #emailForm='ngModel'>
          <div class="label mb-3">
            E-Mail
          </div>
          <input type="password" placeholder="password" [(ngModel)]="password" name="password">
          <div class="label mb-3">
            Passwort
          </div>
          <div class="d-flex justify-content-between">
            <div class="checkbox mb-5">
              <label>
                <input type="checkbox" name="remember_me">
                Angemeldet bleiben
              </label>
            </div>
            <div class="forget-password">
              <a routerLink="/password-assistance">Passwort vergessen?</a>
            </div>
          </div>
          <button type="submit">Einloggen</button>
        </form>
      </div>
    </div>
    <div class="footer d-flex align-items-center justify-content-center">
      <div class="row links">
        <a routerLink="/impressum">Impressum</a>
        <div class="divider">
          |
        </div>
        <a routerLink="/datenschutz">Datenschutz</a>
      </div>
    </div>
  </div>

</div>
