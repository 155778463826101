<div class="hero" xmlns:safe="http://www.w3.org/1999/xhtml">
  <div class="text" *ngIf="me$ | select: 'me' |  async as user">
    Hallo <span>{{ user.first_name }}</span>, willkommen zurück!
  </div>
</div>

<div *ngIf="footer$ | select: 'settings' | async as footer">
  <div class="d-flex justify-content-center align-items-center">
    <p class="well" (click)="isOpen = !isOpen" *ngIf="isOpen"> Video ausblenden <i class="las la-angle-up"></i></p>
    <p class="well" (click)="isOpen = !isOpen" *ngIf="!isOpen"> Video zeigen <i class="las la-angle-down"></i></p>
  </div>
  <div *ngIf="isOpen" class="dashboard-video d-flex justify-content-center align-items-center">
    <div class="center" [innerHTML]="settingByKey(footer, 'dashboard_video').value | safe: 'html'"></div>
  </div>
</div>



<app-help-cta></app-help-cta>
