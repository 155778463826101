import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../../../../core/services/layout.service';
import {ForgetPasswordGQL} from '../../../../services/graphql.service';

@Component({
  selector: 'app-password-assistance',
  templateUrl: './password-assistance.component.html',
  styleUrls: ['./password-assistance.component.scss']
})
export class PasswordAssistanceComponent implements OnInit, OnDestroy {
  email: any;
  message = false;

  constructor(public layoutService: LayoutService,
              public forgetPassword: ForgetPasswordGQL) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.layoutService.showLayout = false;
    });
  }

  ngOnDestroy(): void {
    this.layoutService.showLayout = true;
  }

  sendMail(): void {
    this.forgetPassword.mutate({
      email: this.email
    }).subscribe(res => {
      this.message = true;
    });
  }
}
