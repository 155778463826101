import { Pipe, PipeTransform } from '@angular/core';
import {isObservable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoadingService} from "../../core/services/loading.service";

@Pipe({
  name: 'withLoading'
})
export class WithLoadingPipe implements PipeTransform {

  constructor(private loadingService: LoadingService) {}

  transform(value: any, ...args: any[]): any {
    return isObservable(value)
      ? value.pipe(tap(() => this.loadingService.finish()))
      : value;
  }

}
