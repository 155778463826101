import { Component, OnInit } from '@angular/core';
import {FooterGQL} from "../../../services/graphql.service";

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor(public footer$: FooterGQL) { }

  ngOnInit(): void {
  }

  settingByKey(all, key) {
    return all.filter(setting => setting.key === key)[0];
  }

}
