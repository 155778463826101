import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-course-step-item',
  templateUrl: './course-step-item.component.html',
  styleUrls: ['./course-step-item.component.scss']
})
export class CourseStepItemComponent implements OnInit {
  @Input() isDone = false;


  constructor() { }

  ngOnInit(): void {
  }

}
